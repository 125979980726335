import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import icono from '../../../../../assets/img/icons/search.svg';
import { api } from "api";
import classnames from 'classnames'
class HeaderBtnSearch extends Component {
    render() {
        //Props de search
        const { search, link, textBtn, textBtn2, link2, searchChange, placeholder } = this.props;

        const updateGarages = () => {
            api.get('garage/put_activate_or_inactivate')
            .catch((error) => {
                console.log(error)
            }
            )
        }

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                    {(link !== undefined || textBtn2 !== undefined) &&
                        <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            {(textBtn2 !== undefined) && (
                                <Link to={link2} className={`btn btn-outline-gris text-16 mr-4 ${search !== undefined ? "btn-flex3" : "btn-flex2"} ${textBtn2 == "Salir" ? "btn-salir " : ''}`}
                                onClick={() => {updateGarages()}}>{textBtn2}</Link>
                            )}
                            {(link !== undefined) && (
                                <Link to={link} className={`btn btn-primary text-18 bold${search !== undefined ? "btn-flex3" : "btn-flex2"}`}>{textBtn}</Link>
                            )}
                        </div>
                    }
                    <div className="espacio1" />
                    {(search !== undefined) && (
                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class HeaderImportExcel   extends Component {
    render() {
        //Props de search
        const { search, link, textBtn, textBtn2, link2, searchChange, placeholder, to2, btn2Click } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                    {(link !== undefined || textBtn2 !== undefined) &&
                        <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                                {to2 !== undefined && (
                                    <button
                                        onClick={btn2Click}
                                        className="btn btn-primary text-18 bold m-1 align-self-center px-5 font-weight-bold"
                                    >
                                        {to2}
                                    </button>
                                )}
                                {/* {btnExport && btnExport} */}
                                 {/* <Link to={link2} className={`btn btn-outline-gris text-16 mr-4 ${search !== undefined ? "btn-flex3" : "btn-flex2"} ${textBtn2 == "Salir" ? "btn-salir " : ''}`}>{textBtn2}</Link> */}
                            
                            {(link !== undefined) && (
                                <Link to={link} className={`btn btn-primary text-18 bold${search !== undefined ? "btn-flex3" : "btn-flex2"}`}>{textBtn}</Link>
                            )}
                        </div>
                    }
                    <div className="espacio1" />
                    {(search !== undefined) && (
                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class HeaderSearch extends Component {
    render() {
        //Props de search
        const { search, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                        <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 `}>
                        </div>
                    <div className="espacio1" />
                    {(search !== undefined) && (
                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class HeaderOneFilter extends Component {
    render() {
        const { filtro1, filtroText1 } = this.props;
        //Props de search
        const { search, link, textBtn, textBtn2, disabledBtn2, btn2Click, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row col-12 mb-4 px-0">
                    <div className={`mb-3 mb-lg-0 d-flex flex-row justify-content-start align-items-center justify-content-lg-start px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {(link !== undefined) && (
                            <Link to={link} className={`btn btn-primary btn-flex mx-1`}>{textBtn}</Link>
                        )}
                        {(textBtn2 !== undefined) && (
                            <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className={`btn btn-secondary mx-1 btn-flex`}>{textBtn2}</button>
                        )}
                    </div>
                    <div className={`d-flex flex-column flex-sm-row justify-content-start align-items-center justify-content-lg-start px-0 mx-0 flex-1`}>
                        <div className="d-flex flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText1 || ""}</span>
                            {filtro1 && filtro1}
                        </div>
                        {(search !== undefined) && (
                            <div className={`flex-1 input-header-2`}>
                                <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

class HeaderTwoFilter extends Component {
    render() {
        const { filtro1, filtroText1, filtro2, filtroText2 } = this.props;
        //Props de search
        const { search, link, textBtn, textBtn2, disabledBtn2, btn2Click, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row col-12 mb-4 px-0">
                    <div className={`mb-3 mb-lg-0 d-flex flex-row justify-content-start align-items-center justify-content-lg-start px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {(link !== undefined) && (
                            <Link to={link} className={`btn btn-primary btn-flex mx-1`}>{textBtn}</Link>
                        )}
                        {(textBtn2 !== undefined) && (
                            <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className={`btn btn-secondary mx-1 btn-flex`}>{textBtn2}</button>
                        )}
                    </div>
                    <div className={`d-flex flex-column flex-sm-row justify-content-start align-items-center justify-content-lg-start px-0 mx-0 flex-1`}>
                        <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText1 || "Ingrese el titulo"}</span>
                            {filtro1 && filtro1}
                        </div>
                        <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText2 || "Ingrese el titulo"}</span>
                            {filtro2 && filtro2}
                        </div>
                        {(search !== undefined) && (
                            <div className={`flex-1 input-header-2`}>
                                <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

class SearchInput extends Component {
    static propTypes = {
        search: PropTypes.string.isRequired,
        searchChange: PropTypes.func.isRequired,
    };

    render() {
        const { search, searchChange, placeholder } = this.props;

        return (
            <div className="toolbar-search">
                <input
                    className="form-control search-input bg-white"
                    style={{ backgroundImage: `url(${icono})` }}
                    id="buscador"
                    type="text"
                    placeholder={placeholder ? placeholder : "Buscar..."}
                    ref={(node) => {
                        this.buscar = node;
                        if (this.buscar) {
                            this.buscar.value = search;
                        }
                    }}
                    onChange={() => {
                        if (this.buscar) {
                            searchChange(this.buscar.value);
                        }
                    }}
                    autoComplete="off"
                />
            </div>
        );
    }
}

class HeaderReportFilter extends Component {
    render() {
        const { filtro1, filtro2, filtro3, filtroText3, filtro4, filtroText4, btnExport, btnImprimir } = this.props;

        return (
            <div>
                <div className="d-flex w-100">
                    <div className="d-flex flex-1 flex-column flex-lg-row col-12 mb-4 px-0">
                        <div className={`d-flex flex-column flex-sm-row justify-content-start justify-content-lg-start px-0 mx-0 flex-1`}>
                            <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                                {filtro1 && filtro1}
                            </div>
                            <div className="d-flex ml-1 mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                                {filtro2 && filtro2}
                            </div>
                            <div className="d-flex ml-2 align-items-center">
                                <span className="">{filtroText3 || "Ingrese el titulo"}</span>
                            </div>
                            <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                                {filtro3 && filtro3}
                            </div>
                            <div className="d-flex ml-2 align-items-center">
                                <span className="">{filtroText4 || "Ingrese el titulo"}</span>
                            </div>
                            <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                                {filtro4 && filtro4}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100">
                    <div className="d-flex flex-1 flex-column flex-lg-row col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 px-0">
                        <div className={`d-flex flex-column flex-sm-row justify-content-end justify-content-lg-end px-0 mx-0 flex-1`}>
                            <div className="d-flex mx-1 flex-0 align-items-center mb-3 mb-lg-0 mb-sm-0 ">
                                {btnExport && btnExport}
                            </div>
                            <div className="d-flex mx-1 flex-0 align-items-center ">
                                {btnImprimir && btnImprimir}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { HeaderBtnSearch, HeaderSearch, HeaderOneFilter, HeaderTwoFilter, HeaderReportFilter, SearchInput, HeaderImportExcel }
