import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'
import { RenderDollar } from '../../../Utils/renderField/renderReadField';

export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <Card
                titulo="Planes"
                subTitulo="Suscripciones"
                header={
                    <HeaderBtnSearch
                        link="plan/create"
                        textBtn="Crear plan"
                        {...this.props}
                    />
                }
            >
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='15%'
                        dataFormat={standardActions({ editar: "plan", eliminar })}
                    />
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                    >
                        PLAN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monthly_fee"
                        dataFormat={(cell, row) => {
                            return <RenderDollar value={row && row.monthly_fee ? row.monthly_fee : 0} />
                        }}
                    >
                        TARIFA MENSUAL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="annual_fee"
                        dataFormat={(cell, row) => {
                            return <RenderDollar value={row && row.annual_fee ? row.annual_fee : 0} />


                        }}
                    >
                        TARIFA ANUAL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="amount_per_additional_order"
                        dataFormat={(cell, row) => {
                            return <RenderDollar value={row && row.amount_per_additional_order ? row.amount_per_additional_order : 0} />
                        }}
                    >
                        ORDEN EXTRA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="work_orders"
                        dataFormat={cell => cell >= 1000000 ? "Ilimitadas" : cell}
                    >
                        #ORDENES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="enable_type_work_orders"
                        dataSort
                    >
                        TIPO ORDENES ACTIVAS
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
