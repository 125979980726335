import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import { PrintContainer, actions as printActions } from '../../../Utils/Print';
import {Async} from "react-select";
import { api } from "api";
import { Link } from "react-router-dom";
import { RenderDollar } from '../../../Utils/renderField/renderReadField';
import { HeaderReportFilter } from '../../../Utils/Header'
import moment from 'moment/moment';

let listaPago = [], listaAnios=[], listaMes=[], listaPlan=[]
export default class Listar extends Component {
    state = {
        anio:[],
        mes : [],
        anioId: 0,
        plan: [],
        mesId: 0,
        pago: [],
        planId: 0,
        BreakLineCenter: {
            whiteSpace: 'normal', 
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        },
    }
    
    getAnios = (search) => {
        let data = []
        return api.get('report/get_year_report', {search} ).then((response) => {
            response.years.map(element => {
                return(
                    data.push({
                        id: element, label: element
                    })
                )
            })
            listaAnios = data ? data : []; 
            const year = listaAnios[listaAnios.length - 1].id
            this.getMeses(this.props.filtro_anio.id)
            return listaAnios
        }).catch((error) => {
            console.log(error)
        })
    }

    getMeses = (...datos) => {
        let resp = []
        let params = {}

        if (typeof(datos[0]) === 'number' ) {
            if (datos[0] === 0) {
                this.setState({mes: []});
                this.setState({anioId: 0});
                return []
            }
            params.anio = datos[0]
            this.setState({anioId: params.anio})        
        }else{
            if (this.state.anioId === 0) {
                this.setState({mes:[]})
                return [];
            }
            params.anio = this.state.anioId
            params.search = datos[0]
        }
        return api.get('report/get_month_report', params).then((response) => {
            response.months.map(element => {
                if (element == 1) {resp.push({id: element, label: "Enero"})}
                if (element == 2) {resp.push({id: element, label: "Febrero"})}
                if (element == 3) {resp.push({id: element, label: "Marzo"})}
                if (element == 4) {resp.push({id: element, label: "Abril"})}
                if (element == 5) {resp.push({id: element, label: "Mayo"})}
                if (element == 6) {resp.push({id: element, label: "Junio"})}
                if (element == 7) {resp.push({id: element, label: "Julio"})}
                if (element == 8) {resp.push({id: element, label: "Agosto"})}
                if (element == 9) {resp.push({id: element, label: "Septiembre"})}
                if (element == 10) {resp.push({id: element, label: "Octubre"})}
                if (element == 11) {resp.push({id: element, label: "Noviembre"})}
                if (element == 12) {resp.push({id: element, label: "Diciembre"})}
                return resp
            })
            this.setState({mes: resp})
            listaMes = resp ? resp : []; 
            this.getPlans(this.props.filtro_mes.id)
            return listaMes           
        }).catch((error) => {
            console.log(error)
        })
    }    

    getPlans = (...datos) => {
        let params = {}

        if (typeof(datos[0]) === 'number' ) {
            if (datos[0] === 0) {
                this.setState({plan: []});
                this.setState({mesId: 0});
                // this.setState({anioId: 0})
                return []
            }
            params.anio = this.state.anioId
            params.mes = datos[0]
            this.setState({anioId: params.anio})        
            this.setState({mesId: params.mes})
        }else{
            if (this.state.mesId === 0 && this.state.anioId === 0) {
                this.setState({plan:[]})
                return [];
            }
            params.anio = this.state.anioId
            params.mes = this.state.mesId
            params.search = datos[0]
        }

        return api.get('report/get_plan_report', params).then((response) => {
            if (response) {
                this.setState({plan: response.plans})
                listaPlan = response.plans ? response.plans : []; 
                this.getPagos(this.props.filtro_plan.id)
                return listaPlan
            }
            return []
        }).catch((error) => {
            console.log(error)
        })
    }
    
    getPagos = (...datos) => {
        let resp = []
        let params = {}

        if (typeof(datos[0]) === 'number' ) {
            if (datos[0] === 0) {
                this.setState({pago: []});
                this.setState({planId: 0});
                return []
            }
            params.anio = this.state.anioId
            params.mes = this.state.mesId
            params.plan = datos[0]
            this.setState({anioId: params.anio})        
            this.setState({mesId: params.mes})        
            this.setState({planId: params.plan})        
        }else{
            if (this.state.planId === 0) {
                this.setState({pago:[]})
                return [];
            }
            params.anio = this.state.anioId
            params.mes = this.state.mesId
            params.plan = this.state.planId
            params.search = datos[0]
        }

        return api.get('report/get_pay_report', params).then((response) => {
            response.payments.map(element => {
                if (element == 11) {resp.push({id: element, label: "Mensual"})}
                if (element == 21) {resp.push({id: element, label: "Anual"})}
                return resp
            })

            this.setState({pago: resp})
            listaPago = resp ? resp : []; return listaPago
        }).catch((error) => {
            console.log(error)
        })
    }    
    
    componentWillMount() {
        const { page } = this.props;
        this.props.listar(page)
    }

    imprimirReporte = (event) => {
        printActions.print('table-REPORT');
    }

    render() {        
        const { listar, descargarReporte, onSortChange, filtroAnioChange, filtroMesChange, filtroPlanChange, filtroPagoChange } = this.props; //Funciones
        const { data, loader, page, filtro_pago, filtro_plan} = this.props; //Constantes
        return (
            <Card
                titulo="Reporte"
                subTitulo=""
                header={
                    <HeaderReportFilter
                        filtro1={
                            <Async
                                isSearchable={false}
                                value={this.props.filtro_anio}
                                className="react-select-container filter w-100"
                                defaultOptions={true}
                                loadOptions={this.getAnios}
                                onChange={selected => {
                                    filtroAnioChange(selected);
                                    filtroMesChange('');
                                    filtroPlanChange('');
                                    filtroPagoChange('');
                                    this.getMeses(selected && selected.id ? selected.id : 0)
                                }}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['label'])}
                            />
                        }
                        filtro2={
                            <Async
                            value={this.props.filtro_mes}
                            className="react-select-container filter w-100"
                            backspaceRemovesValue={false}
                            placeholder="Todos"
                            multi={false}
                            autoload={false}
                            cache={false}
                            defaultOptions={this.state.mes}
                            loadOptions={this.getMeses}
                            onChange={selected => {
                                if (!selected) {
                                    this.getMeses(this.state.anioId)                                    
                                }
                                filtroMesChange(selected);
                                filtroPlanChange('');
                                filtroPagoChange('');
                                this.getPlans(selected && selected.id ? selected.id:0)
                            }}
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['label'])}
                        />
                        }
                        filtroText3="Plan"
                        filtro3={
                            <Async
                                className="react-select-container filter w-100"
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable={true}
                                placeholder="Todos"
                                multi={false}
                                autoload={false}
                                cache={false}
                                defaultOptions={this.state.plan}
                                loadOptions={this.getPlans}
                                onChange={selected => {
                                    if (!selected) {
                                        this.getPlans("")
                                    }
                                    filtroPlanChange(selected);
                                    filtroPagoChange('');
                                    this.getPagos(selected && selected.id ? selected.id : 0)
                                }}
                                value={filtro_plan}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['label'])}
                            />
                        }
                        filtroText4="Modalidad de Pago"
                        filtro4={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={filtro_pago}
                                isSearchable={true}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className="react-select-container filter w-100"
                                defaultOptions={this.state.pago}
                                placeholder="Todos"
                                loadOptions={this.getPagos}
                                onChange={selected => {
                                    if (!selected) {
                                        this.getPagos("")
                                    }
                                    filtroPagoChange(selected);
                                }}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['label'])}
                            />
                        }
                        btnExport={
                            <button type="submit" className="btn btn-outline-rojo  w-100" onClick={descargarReporte} disabled={loader}>EXPORTAR</button>
                        }
                        btnImprimir={
                            <Link className="btn btn-outline-rojo w-100" to={`/report_print`}>IMPRIMIR</Link>
                        }
                        {...this.props}
                    />
                }
            >
            <PrintContainer name="table-REPORT">
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                <TableHeaderColumn
                        dataField="payment_date"
                        dataSort
                        width='150'
                        dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY') : '---'}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        FECHA PAGO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="garage"
                        isKey
                        dataSort
                        width='150'
                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        TALLER
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="garage"
                        dataSort
                        width='200'
                        dataFormat={(cell) => cell ? `${cell.city}, ${cell.country.name}` : ""}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        UBICACIÓN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="sucursales_count"
                        dataSort
                        width='150'
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        SUCURSALES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="name_plan"
                        dataSort
                        width='150'
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        SUSCRIPCIÓN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="payment"
                        dataSort
                        width='150'
                        dataFormat={(cell) => cell==11 ? "Mensual" : "Anual"}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        PAGO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="ordenes_adicionales"
                        dataSort
                        width='150'
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}

                    >
                        ORDENES ADICIONALES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="amount"
                        dataSort
                        width='150'
                        dataFormat={(cell) => <RenderDollar value={cell}/>}
                        thStyle={this.state.AlignCenter}
                        dataAlign="right"
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="rendimiento"
                        dataSort
                        width='150'
                        dataAlign="center"
                        dataFormat={(cell, row) => {
                            const n = parseFloat(cell).toFixed(2)
                            return <RenderDollar prefix={row.garage.currency} value={(n > 0 ? n : '0.00')}/>
                        }}
                        thStyle={this.state.AlignCenter}
                        dataAlign="right"
                    >
                        RENDIMIENTO
                    </TableHeaderColumn> 
                </Grid>
            </PrintContainer>           
            </Card>
        )
    }
}
