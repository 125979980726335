import React, { Fragment } from 'react';


function CardForm(props) {
    return (
        <Fragment>
            <div className="mb-3">
                <h3 className="text-30 my-0">{props.titulo}</h3>
            </div>
            <div className="card-small">
                <div className='card-content-form px-0 px-0'>
                    <div className="col-12 col-lg-9">
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment >

    )
}

export default CardForm;
