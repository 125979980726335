import React, { Component, Fragment } from 'react'
import moment from 'moment/moment';
import Card from '../../../Utils/Cards/Card';
import Grid from '../../../Utils/Grid';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { RenderDollar } from '../../../Utils/renderField/renderReadField';

export default class ReportImp extends Component {
    state = {
        date_after : moment().format('DD/MM/YYYY'),
        fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A'),
        name_print_component: 'boleta-report',
        BreakLineCenter: {
            whiteSpace: 'normal', 
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        },
    }

    
    componentDidMount(){
        const { imprimirReport } = this.props;
        imprimirReport();
    }
    imprimirBoleta = (event) => {
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
        }, () => {
            printActions.print(this.state.name_print_component);
        });
    }
   
    render() {
        const { profile, data_print, loader } = this.props;
        return (
            <div>
                    <Card>
                        <PrintContainer name={this.state.name_print_component}>
                            <div className="col-12 p-0">
                                <div className="row contenido-impresion d-flex justify-content-center">
                                    <div className="col-12 row">
                                        <div className="col-12 d-flex justify-content-center mt-0">
                                            <div className="col-11 pl-2">
                                                <div className="row col-12 p-0 mt-3 d-flex justify-content-between align-items-center">
                                                    <img
                                                        className="d-inline-block align-top"
                                                        src={require('../../../../../../assets/img/logo.png')}
                                                        alt="Logo"
                                                        height="60px"
                                                    />
                                                    <label className="p-0 m-0 text-18 bold">REPORTE DE MOVIMIENTO DE TALLERES</label>
                                                    <div className="ml-4 mr-4"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-center mt-3">
                                            <div className="col-11">
                                                <div className="row col-12 p-0 mt-2 justify-content-center align-items-start">
                                                    <div className="col-8 p-0 fondo">
                                                        <div className="col-12 d-flex flex-column">
                                                            <span className="p-0 m-0">GENERADA POR</span>
                                                            <span className="gris">{profile.first_name} {profile.last_name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <span className="p-0 m-0">FECHA DE IMPRESIÓN</span>
                                                            <span className="gris">{this.state.fechaHoraImpresion}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div className="row col-12 p-0 mt-3">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">FECHA INICIO</label>
                                                            <span className="gris">{data_print.filters ? moment(data_print.filters.payment_date_after).format('DD/MM/YYYY') : ""}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">FECHA FIN</label>
                                                            <span className="gris">{data_print.filters ? moment(data_print.filters.payment_date_before).format('DD/MM/YYYY') : ""}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center p-0">
                                            <div className=" col-11 form-group np-r p-0 mt-3 mb-1 inicio-tabla">
                                                <Grid hover striped data={data_print} loading={loader} pagination={false}  >
                                                    <TableHeaderColumn
                                                        dataField="payment_date"
                                                        dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY') : '---'}
                                                        thStyle={this.state.AlignCenter}
                                                        tdStyle={this.state.BreakLineCenter}
                                                    >
                                                        FECHA PAGO
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="garage"
                                                        isKey
                                                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                                                        thStyle={this.state.AlignCenter}
                                                        tdStyle={this.state.BreakLineCenter}
                                                    >
                                                        TALLER
                                                    </TableHeaderColumn>
                                                    
                                                    <TableHeaderColumn
                                                        dataField="garage"
                                                        dataFormat={(cell) => cell ? `${cell.city}, ${cell.country.name}` : ""}
                                                        thStyle={this.state.AlignCenter}
                                                        tdStyle={this.state.BreakLineCenter}
                                                    >
                                                        UBICACIÓN
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="sucursales_count"
                                                        thStyle={this.state.AlignCenter}
                                                        tdStyle={this.state.BreakLineCenter}
                                                    >
                                                        SUCURSALES
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="name_plan"
                                                        thStyle={this.state.AlignCenter}
                                                        tdStyle={this.state.BreakLineCenter}
                                                    >
                                                        SUSCRIPCIÓN
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="payment"
                                                        dataFormat={(cell) => cell==11 ? "Mensual" : "Anual"}
                                                        thStyle={this.state.AlignCenter}
                                                        tdStyle={this.state.BreakLineCenter}
                                                    >
                                                        PAGO
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="ordenes_adicionales"
                                                        thStyle={this.state.AlignCenter}
                                                        tdStyle={this.state.BreakLineCenter}
                                                    >
                                                        ORDENES ADICIONALES
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="amount"
                                                        dataFormat={(cell) => <RenderDollar value={cell}/>}
                                                        thStyle={this.state.AlignCenter}
                                                        dataAlign="right"
                                                    >
                                                        MONTO
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="rendimiento"
                                                        dataFormat={(cell, row) => {
                                                            const n = parseFloat(cell).toFixed(2)
                                                            return <RenderDollar prefix={row.garage.currency} value={(n > 0 ? n : '0.00')}/>
                                                        }}
                                                        thStyle={this.state.AlignCenter}
                                                        dataAlign="right"
                                                        dataFormat={(cell, row) => {
                                                            const n = parseFloat(cell).toFixed(2)
                                                            return <RenderDollar prefix={row.garage.currency} value={(n > 0 ? n : '0.00')}/>
                                                        }}
                                                    >
                                                        RENDIMIENTO
                                                    </TableHeaderColumn>
                                                    
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PrintContainer>
                    </Card>
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button type="button" className="btn btn-outline-dark mr-5" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.goBack();
                                    }}>CERRAR</button>
                                    <button type="submit" className="btn btn-outline-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
