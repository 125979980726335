import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import { Field, reduxForm } from 'redux-form';
import { renderField, renderNumber } from '../../../Utils/renderField/renderField';
import { ButtonSalirGuardar } from '../../../Utils/Button';
import { validationAdministrador } from '../../../../../utility/validationForm'


const AdminTallerForm = (props) => {
    const { handleSubmit, item = {} } = props;
    const {
        proprietor = {}
    } = item || {}
    return (
        <Fragment>
            <div className='card-content-form2 px-0 px-0'>
                <div className="col-12 col-lg-9">
                    <form
                        id="TallerForm"
                        onSubmit={handleSubmit}
                    >
                        <div className="row card-form">

                            <div className="col-md-6 padding-custom mb-3">
                                <span className="d-block">Username</span>
                                <span className="text-18 bold negro d-block">{proprietor.username}</span>
                            </div>

                            <div className="col-md-6 padding-custom mb-3">
                                <span className="d-block">E-mail</span>
                                <span className="text-18 bold negro d-block">{proprietor.email}</span>
                            </div>

                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="proprietor.first_name">Nombre*</label>
                                <Field name="proprietor.first_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.first_name.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="proprietor.last_name">Apellido*</label>
                                <Field name="proprietor.last_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.last_name.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="proprietor.phone_number">Teléfono*</label>
                                <Field name="proprietor.phone_number" component={renderNumber} type="text" className="form-control" isNumericString={true} maxLength={validationAdministrador.phone_number.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3 d-flex">
                                <p className="text-right azul text-12 bold align-self-end ml-auto mb-0">* Campos requeridos</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ButtonSalirGuardar formSubmit="TallerForm" link="/garages" />
        </Fragment>
    );

}

export default reduxForm({
    form: 'TallerForm', // a unique identifier for this form
})(AdminTallerForm);
