import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate
} from 'validate-redux-form';

import {
    renderField,
    renderNumber2,
    renderNumber,
    renderTextArea,
    renderDatePicker,
} from '../../../Utils/renderField/renderField'
import { RenderDollar } from '../../../Utils/renderField/renderReadField';
import { validationPayment } from '../../../../../utility/validationForm';
import { ButtonSalirGuardar, ButtonSalir } from '../../../Utils/Button';
import { connect } from 'react-redux'

const PagoForm = (props) => {
    const { handleSubmit, backUrl, data_garage = {}, amount_pay, amount_pay_only } = props;
    const {
        name: nombre_taller = "",
        active_payment_text = "",
        orders_additionals = 0,
        pending_amount = 0,
        amount_per_additional_order = 0,
        plan = {}
    } = data_garage || {}
    
    // const MAX_VAL = 0;
    const MIN_VAL = plan.monthly_fee ? plan.monthly_fee: 1;

    const {
        name: nombre_plan = "",
    } = plan || {}

    let date = new Date();
    let ultime_day = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    date.setDate(date.getDate() - date.getDate()+1);

    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">

                <div className="col-12 padding-custom mb-4">
                    <span className="text-30 bold d-block" >Taller: {nombre_taller}</span>
                </div>
                <div className="col-12 padding-custom mb-4">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #323232 solid", paddingBottom: 5 }}>DATOS DEL PLAN</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <span className="d-block">Nombre</span>
                    <span className="text-18 bold negro d-block">{nombre_plan}</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <span className="d-block">{active_payment_text}</span>
                    <span className="text-18 bold negro d-block">
                        {amount_pay_only ?
                            <RenderDollar value={amount_pay_only} className="text-18 bold negro" />
                            :
                            "--"
                        }
                    </span>
                </div>
                {orders_additionals > 0 &&
                    <div className="col-md-6 padding-custom mb-3">
                        <span className="d-block rojo">Orden adicional</span>
                        <span className="text-18 negro ">
                            {orders_additionals} x {" "}
                            <RenderDollar value={amount_per_additional_order} className="text-18 negro" />
                            {" = "}
                            <RenderDollar value={pending_amount} className="text-18 bold negro" />
                        </span>
                    </div>
                }
                <div className="col-12 padding-custom mb-4 mt-2">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>DATOS DEL PAGO</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="payment_date">Fecha de pago*</label>
                    <Field name="payment_date"
                    component={renderDatePicker}
                    // minDate={date}
                    // maxDate={ultime_day}
                    className="form-control" />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="amount">Monto*</label>
                    <Field MIN_VAL={MIN_VAL} name="amount" decimalScale={2} prefix="$ " component={renderNumber} className="form-control" />
                </div>
                <div className="col-md-12 padding-custom mb-3">
                    <label htmlFor="reference"># Referencia*</label>
                    <Field name="reference" component={renderField} type="text" className="form-control" maxLength={validationPayment.reference.MaxLength} />
                </div>
                <div className="col-md-12 padding-custom mb-3">
                    <label htmlFor="observations">Observaciones</label>
                    <Field name="observations" component={renderTextArea} type="text" className="form-control" />
                </div>
                <div className="col-12 padding-custom mb-3">
                    <p className="text-right azul text-12 bold">* Campos requeridos</p>
                </div>
            </div>
            {amount_pay ?
                < ButtonSalirGuardar link={backUrl} />
                :
                <ButtonSalir link={backUrl} />
            }
        </form>
    );
};

let PagoForm2 = reduxForm({
    form: 'PagoForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            reference: validationPayment.reference.Combine,
            amount: validationPayment.amount.Combine,
            payment_date: validationPayment.payment_date.Combine,
        })
    },
})(PagoForm);

export default PagoForm2 = connect(
    (state, props) => ({
        initialValues: {
            amount: props.amount_pay,
            garage: props.id_garage,
            amount_pay_only: props.amount_pay_only,
        }
    }),
)(PagoForm2)
