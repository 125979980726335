import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/sucursales';
import { actions as actions_taller } from '../../../../../redux/modules/talleres';

import Listar from './Listar';


const ms2p = (state) => {
    return {
        ...state.sucursales,
        datos_taller: { ...state.talleres }
    };
};

const md2p = {
    ...actions,
    leer_taller: actions_taller.leer
};

export default connect(ms2p, md2p)(Listar);
