import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemState
} from 'react-accessible-accordion';
import { MANAGEMENT_SHOPS_BRANCHES, REPORTS, USER_MANAGEMENT, ROLE_MANAGEMENT, SUBSCRIPTION_MANAGEMENT, PAYMENT_MANAGEMENT } from '../../../../utility/constants';

const dashboard = require("assets/img/icons/menu.svg");
const dashboard_activo = require("assets/img/icons/menu01.svg");
const settings = require("assets/img/icons/settings.svg");
const settings_activo = require("assets/img/icons/settings01.svg");
const talleres = require("assets/img/icons/talleres.svg")
const talleres_activo = require("assets/img/icons/talleres01.svg")
const reporte_activo = require("assets/img/dashboard/statistics_rojo.svg");
const reporte = require("assets/img/icons/statistics.svg")
const logo = require('assets/img/logo.png');
const point = require('assets/img/punto_sidebar.svg');
const point_disabled = require('assets/img/punto_sidebar_inactivo.svg');
const settings_user = require('assets/img/settings_user.svg')
const defaultAvatar = require('assets/img/avatar-placeholder.png');

const PERFIL = 'PERFIL';
const MODULO_DASHBOARD = 'DASHBOARD';
const MODULO_CONFIGURACION = 'CONFIGURACION';
const MODULO_TALLER = 'TALLER';
const MODULO_REPORTE = 'REPORTE';

class SideBar extends Component {
    state = {
        isFirstSelected: false,
        isSidebarExpanded: false,
    }
    constructor(props) {
        super(props);
    }
    mouseEnter = () => {
        this.setState({ isSidebarExpanded: true })
    }
    mouseLeave = () => {
        this.setState({ isSidebarExpanded: false })
    }

    /**
     * En base al path actual se determina si un item se marca como activo o no.
     * @param idModulo Una de las constantes definidas con el prefijo MODULO_
     * @param path la URL a comparar.
     * @param isItemSelected indica si el item en cuestión se le dió clic o no.
     */
    isItemSelected(idModulo, path, isItemSelected) {
        const isFirstSelected = this.state.isFirstSelected;
        isItemSelected && !isFirstSelected && (this.setState({ isFirstSelected: true }));
        let isMatch = false;
        const isSidebarExpanded = this.state.isSidebarExpanded;
        switch (idModulo) {
            case PERFIL:
                isMatch = path === '#/user-profile';
                break;
            case MODULO_DASHBOARD:
                isMatch = path === '#/';
                break;
            case MODULO_REPORTE:
                isMatch = path === '#/report';
                break;
            case MODULO_TALLER:
                isMatch = path === '#/garages';
                break;
            case MODULO_CONFIGURACION:
                isMatch = path.includes('/users') || path.includes('/roles') || path.includes('/plans') || path.includes('/brands') || path.includes('/car_types');
                break;
            default:
                isMatch = false;
                break;
        }
        return !isFirstSelected && !isItemSelected && isMatch || isFirstSelected && isMatch && !isSidebarExpanded || isFirstSelected && isItemSelected && isSidebarExpanded;
    }


    render() {
        const { toggleOpen, navToggle, user, logOut } = this.props;
        return (
            <aside className={`main-sidebar ${toggleOpen ? '' : 'open'}`} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>

                <div className="sidebar-cont" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                    <div className="sidebar-cont-icon" ></div>
                    <div className='sidebar-cont-opc'  >
                        <div className="text-center px-2" style={{ paddingTop: 30 }}>
                            <img
                                style={{ height: 60, width: 120 }}
                                className="main-logo-sidebar"
                                src={logo}
                                alt="Logo" />
                        </div>
                    </div>
                    <div className="sidebar-principal" >
                        <div className="main-navbar">
                            <a
                                className="toggle-sidebar d-flex d-md-none d-lg-none bg-white "
                                onClick={navToggle}
                            >
                                <i className="material-icons" style={{ margin: "auto" }}></i>
                            </a>
                        </div>
                        <div className="nav-wrapper w-100 spacing-items">
                            <Accordion className="nav--no-borders flex-column nav">
                                <div className="nav-item pb-4">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(PERFIL, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/user-profile" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={(user && user.avatar) ? user.avatar : defaultAvatar}
                                                                className="icon-menu-profile" alt="Avatar user" />
                                                            <span className="text-12 bold">{(user && user.first_name) ? user.first_name + ' ' + user.last_name : 'Usuario Invitado'}</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_DASHBOARD, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={isSelected ? dashboard_activo : dashboard}
                                                                className="icon-menu" alt="" />
                                                            <span className="text-14 medium">Dashboard</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                    {(user.permissions.includes(MANAGEMENT_SHOPS_BRANCHES) || user.permissions.includes(PAYMENT_MANAGEMENT)) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_TALLER, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/garages" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? talleres_activo : talleres}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Talleres</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}
                                    {user.permissions.includes(REPORTS) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_REPORTE, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/report" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? reporte_activo : reporte}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Reporte</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}

                                    {((user.permissions.includes(SUBSCRIPTION_MANAGEMENT)) || (user.permissions.includes(ROLE_MANAGEMENT)) || (user.permissions.includes(USER_MANAGEMENT))) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_CONFIGURACION, location.hash, expanded);
                                                            return <div className={`nav-link${isSelected ? ' active' : ''}`} >
                                                                <img src={isSelected ? settings_activo : settings}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Administración</span>
                                                            </div>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_CONFIGURACION, location.hash, expanded);
                                                    const isUsuarios = location.hash.includes('/users')
                                                    const isRoles = location.hash.includes('/roles')
                                                    const isPlanes = location.hash.includes('/plans')
                                                    const isBrand = location.hash.includes('/brands')
                                                    const isCarType = location.hash.includes('/car_types')
                                                    return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                        {user.permissions.includes(USER_MANAGEMENT) && (
                                                            <div className="sub-nav-item">
                                                                <NavLink onClick={navToggle} to="/users" exact className="nav-link" activeClassName={'active'}
                                                                    isActive={(match, location) => location.pathname.includes('/users')}>
                                                                    <img src={isUsuarios ? point : point_disabled}
                                                                        className="icon-menu-sub" alt="" />
                                                                    <span style={{ color: isUsuarios ? "#cd0a0a" : "#323232" }} className="text-12 medium">Usuarios</span>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {user.permissions.includes(ROLE_MANAGEMENT) && (
                                                            <div className="sub-nav-item">
                                                                <NavLink onClick={navToggle} to="/roles" exact className="nav-link" activeClassName={'active'}
                                                                    isActive={(match, location) => location.pathname.includes('/roles')}>
                                                                    <img src={isRoles ? point : point_disabled}
                                                                        className="icon-menu-sub" alt="" />
                                                                    <span style={{ color: isRoles ? "#cd0a0a" : "#323232" }} className="text-12 medium">Roles</span>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {user.permissions.includes(SUBSCRIPTION_MANAGEMENT) && (
                                                            <div className="sub-nav-item">
                                                                <NavLink onClick={navToggle} to="/plans" exact className="nav-link" activeClassName={'active'}
                                                                    isActive={(match, location) => location.pathname.includes('/plans')}>
                                                                    <img src={isPlanes ? point : point_disabled}
                                                                        className="icon-menu-sub" alt="" />
                                                                    <span style={{ color: isPlanes ? "#cd0a0a" : "#323232" }} className="text-12 medium">Planes</span>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {user.permissions.includes(SUBSCRIPTION_MANAGEMENT) && (
                                                            <div className="sub-nav-item">
                                                                <NavLink onClick={navToggle} to="/brands" exact className="nav-link" activeClassName={'active'}
                                                                    isActive={(match, location) => location.pathname.includes('/brands')}>
                                                                    <img src={isBrand ? point : point_disabled}
                                                                        className="icon-menu-sub" alt="" />
                                                                    <span style={{ color: isBrand ? "#cd0a0a" : "#323232" }} className="text-12 medium">Marcas</span>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {user.permissions.includes(SUBSCRIPTION_MANAGEMENT) && (
                                                            <div className="sub-nav-item">
                                                                <NavLink onClick={navToggle} to="/car_types" exact className="nav-link" activeClassName={'active'}
                                                                    isActive={(match, location) => location.pathname.includes('/car_types')}>
                                                                    <img src={isCarType ? point : point_disabled}
                                                                        className="icon-menu-sub" alt="" />
                                                                    <span style={{ color: isCarType ? "#cd0a0a" : "#323232" }} className="text-12 medium">Tipos</span>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                    </AccordionItemPanel>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItem>
                                    )}

                                </div>
                                <div className="nav-item pie-sidebar">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        return <a onClick={logOut} href="/" className="nav-item">
                                                            <img src={settings_user}
                                                                className="icon-menu-close" alt="Avatar user" />
                                                            <span className="text-14 medium">Cerrar sesión</span>
                                                        </a>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </div>

            </aside>
        )
    }
}

export default SideBar;
