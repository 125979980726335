import React, { Component } from 'react'
import Card from '../../../Utils/Cards/Card'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import DatosTaller from './DatosTaller'
import DatosAdmin from './DatosAdministrador';
import DatosFacturacion from './DatosFacturacion';
import './styleTab.css';

export default class TabsListar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: null,
        };
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
        }
    }

    setImg = (logo) => {
        this.setState({ logo });
    };

    update = (_data) => {
        let data = { ..._data }
        delete data.receipt_info;

        this.props.editar(
            this.props.match.params.id,
            { ...data, logo: null },
            [{ "file": this.state.logo, "name": "logo" }]
        );
    }

    render() {
        return (
            <Card
                titulo="Detalle taller"
            >
                <div className="d-flex p-2 p-md-5 overflow-tabs">
                    <Tabs
                        defaultActiveKey="DATOS_TALLER"
                        tabBarPosition="top"
                        renderTabBar={() => <ScrollableInkTabBar />}
                        renderTabContent={() => <TabContent />}

                    >
                        <TabPane
                            tab="Datos taller"
                            key="DATOS_TALLER"
                        >
                            <DatosTaller {...this.props} setImg={this.setImg} onSubmit={this.update} />
                        </TabPane>
                        <TabPane
                            tab="Datos administrador"
                            key="DATOS_ADMINISTRADOR"
                        >
                            <DatosAdmin {...this.props} onSubmit={this.update} />
                        </TabPane>
                        <TabPane
                            tab="Datos facturación"
                            key="DATOS_FACTURACION"
                        >
                            <DatosFacturacion receipt_info={this.props.item.receipt_info} />
                        </TabPane>
                    </Tabs>
                </div>
            </Card>
        )
    }
}
