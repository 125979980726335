import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch, HeaderSearch } from '../../../Utils/Header'
import { ROLE_MANAGEMENT } from '../../../../../utility/constants';



export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page, me } = this.props; //Constantes
        return (
            <Card
                titulo="Usuarios"
                
                header={ me.permissions.includes(ROLE_MANAGEMENT) ?
                    <HeaderBtnSearch
                        link="user/create"
                            textBtn="Crear usuario"
                        {...this.props}
                    />
                    :
                    <HeaderSearch {...this.props}/>
                }
            >
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='10%'
                        dataFormat={standardActions({ editar: "user", eliminar })}
                    />
                    <TableHeaderColumn
                        dataField="username"
                        dataSort
                        width="12%"
                    >
                        USUARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="first_name"
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="last_name"
                        dataSort
                    >
                        APELLIDO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="email"
                        dataSort
                    >
                        EMAIL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="phone_number"
                        dataSort
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="role"
                        dataSort
                        dataFormat={(cell) => {
                            if (cell) {
                                return cell.name
                            }

                        }}
                    >
                        ROL
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
