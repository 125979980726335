import React, { Component } from 'react';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import './styleDashboard.css';
import { RenderDollar } from '../../../Utils/renderField/renderReadField';

const statisticsVerde = require("assets/img/dashboard/statistics_verde.svg");
const statisticsRojo = require("assets/img/dashboard/statistics_rojo.svg");
const statisticsAzul = require("assets/img/dashboard/statistics_azul.svg");

import defaultLogo from 'assets/img/place_holder/image_placeholder_small.png'

import { MANAGEMENT_SHOPS_BRANCHES, PAYMENT_MANAGEMENT } from '../../../../../utility/constants';

export default class Dashboard extends Component {

    componentWillMount() {
        const { listar, listarGraph, page } = this.props;
        listar(page);
        listarGraph();
    }

    listar = (page = 1) => {
        const { listar } = this.props;
        return listar(page);
    }

    render() {
        const { onSortChange } = this.props; //Funciones
        const { data, loader, page, me, item } = this.props; //Constantes

        return (
            <div className=" py-4 ">
                <div className="mb-1 mb-md-2">
                    <h1 className="text-45 medium d-inline my-0">Dashboard</h1>
                </div>
                <div className="py-4">
                    <div className="row ">
                        <div className="col-lg-6 col-md-6 p-0">
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">TOTAL DE SUSCRIPCIONES</h2>
                                <CardEmpty >
                                    <div className="mr-2 mb-2">
                                        <div className="mb-1">

                                            <div className="d-flex justify-content-between align-items-center">
                                                <img src={statisticsAzul} className="icon-menu-dash" alt="" />
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="text-50 bold negro">{item.total_garages}</span>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center pr-4">
                                                    <label className="text-18 medium negro"></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </CardEmpty>
                            </div>
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">TOTAL DE SUSCRIPCIONES ACTIVAS</h2>
                                <CardEmpty >
                                    <div className="mr-2 mb-2">
                                        <div className="mb-1">

                                            <div className="d-flex justify-content-between align-items-center">
                                                <img src={statisticsVerde} className="icon-menu-dash" alt="" />
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="text-50 bold negro">{item.total_garages_active}</span>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center pr-4">
                                                    <label className="text-18 medium negro"></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </CardEmpty>
                            </div>
                            <div className="col-lg-12 col-12 pt-3">
                                <h2 className="text-25 medium">VENTAS EN SUSCRIPCIONES AL MES</h2>
                                <CardEmpty >
                                    <div className="mr-2 mb-2">
                                        <div className="mb-1">

                                            <div className="d-flex justify-content-between align-items-center">
                                                <img src={statisticsRojo} className="icon-menu-dash" alt="" />
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <RenderDollar value={item.total_payment_actual ? item.total_payment_actual : 0} className="text-50 bold negro" />
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center pr-4">
                                                    <label className="text-18 medium negro"></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </CardEmpty>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 p-0">
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">SUSCRIPCIONES PENDIENTES DE APROBACIÓN</h2>
                                <Card >
                                    <Grid className="pt-4" hover striped data={data} loading={loader} onPageChange={this.listar} onSortChange={onSortChange} page={page}>
                                        {(me.permissions.includes(MANAGEMENT_SHOPS_BRANCHES) || me.permissions.include, PAYMENT_MANAGEMENT) &&
                                            <TableHeaderColumn
                                                dataField="id"
                                                dataAlign="center"
                                                width='150'
                                                dataFormat={standardActions({ editarTaller: true, verPago: true })}
                                            />
                                        }
                                            <TableHeaderColumn
                                                dataField="logo"
                                                width='100'
                                                isKey
                                                tdStyle={{ filter: 'none' }}
                                                dataFormat={(cell, row) => <img src={cell || defaultLogo} height="30" />}
                                            >
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="name"
                                                dataSort
                                                width='150'
                                            >
                                                TALLER
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="proprietor"
                                                dataFormat={(cell) => cell ? `${cell.phone_number}` : ""}
                                                dataSort
                                                width='150'
                                            >
                                                TELEFONO
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="proprietor"
                                                dataFormat={(cell) => cell ? `${cell.email}` : ""}
                                                width='250'
                                            >
                                                EMAIL
                                            </TableHeaderColumn>
                                    </Grid>
                                </Card>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
