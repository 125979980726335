import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from 'Utils/LoadMask/LoadMask';
const logo = require('assets/img/logo.png');

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return <Redirect to="/" />;
        }
        return (
            <div className="blue-gradient-bg d-flex align-items-center">
                <div className="login-wrapper flex-1 px-sm-5 px-md-0">
                    <div className="card-login ml-md-auto">
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <img src={logo} alt="Alerta" height={50} className="img-fluid" />
                        </div>
                        <div className="mx-5 my-5" style={{borderTop: "solid 2px #323232"}}/>
                        <LoadMask loading={loader} light>
                            <LoginForm onSubmit={onSubmit} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
