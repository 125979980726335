import React, { Component } from 'react';

export default class PrintRootContainer extends Component {
    render() {
        const { children } = this.props
        return (
            <div>
                <div className="dont-print">
                    {children}
                </div>
                <div id="PRINT-ROOT-CONTAINER" className={'print-container'}>
                </div>
            </div>
        )
    }
}
