import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const logo = require('assets/img/logo_navbar.png');
const logo_mobile = require('assets/img/logo_navbar_mobile.png');


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };
    render() {
        const { navToggle } = this.props;

        return (
            <nav className="align-items-between flex-md-nowrap p-0 navbar navbar-light">
                {/* <div className=" w-50 justify-content-center d-inline-flex d-md-none">
                        <img src={logo_mobile} alt="logo" className="img-fluid-nav" />
                </div> */}

                <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                    <div className="ml-3 input-group input-group-seamless" />
                        <img src={logo} alt="logo" className="img-fluid-nav" style={{ maxWidth: "350px" }} />
                    <div className="ml-3 input-group input-group-seamless" />
                </div>

                <ul className="flex-row navbar-nav">
                   
                </ul>
        
                <nav className="nav">
                    <a
                        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none text-center"
                        onClick={navToggle}
                    >
                        <i className="material-icons"></i>
                    </a>
                </nav>
            </nav>
        );
    }
}

export default Navbar;
