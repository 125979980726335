import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import CardLogo from '../../../Utils/Cards/CardLogo'
import { HeaderBtnSearch } from '../../../Utils/Header'
import { RenderDollar, RenderDate } from '../../../Utils/renderField/renderReadField';
import { api } from "api";

export default class Listar extends Component {
    componentWillMount() {
        const { listar, leer_taller, datos_taller } = this.props;
        const { idGarage = 0 } = this.props.match.params
        listar(1, idGarage);
        if (Object.keys(datos_taller.item).length === 0 || datos_taller.item.id != idGarage) {
            leer_taller(idGarage)
        }
        api.get('garage/put_activate_or_inactivate')
        .catch((error) => {
            console.log(error)
        }
        )
    }
    componentWillUnmount() {
        api.get('garage/put_activate_or_inactivate')
        .catch((error) => {
            console.log(error)
        }
        )
    }

    render() {
        const { listar, onSortChange, eliminar, searchChange } = this.props; //Funciones
        const { data, loader, page, datos_taller } = this.props; //Constantes
        const idGarage = this.props.match.params.idGarage
        const rutaEditar = `/garage/${idGarage}/payment`
        return (
            <CardLogo
                titulo="Pagos"
                subTitulo="Taller"
                logo={datos_taller.item.logo}
                nombre_sucursal={datos_taller.item.name}
                header={
                    <HeaderBtnSearch
                        link={`${rutaEditar}/create`}
                        textBtn="Registrar pago"
                        textBtn2="Salir"
                        link2="/garages"
                        {...this.props}
                        searchChange={(e) => {
                            searchChange(e, idGarage)
                        }}
                    />
                }
            >
                <Grid hover striped data={data} loading={loader}
                    onPageChange={(e) => {
                        listar(e, idGarage)
                    }}
                    onSortChange={(e) => {
                        onSortChange(e, idGarage)
                    }}
                    page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='5%'
                        dataFormat={(cell, row) => {
                            let id_last = data.last_payment || 0
                            return (id_last == cell) ?
                                standardActions({ eliminar: (e) => { eliminar(e, idGarage) } })(cell, row)
                                :
                                ""
                        }}
                    />
                    <TableHeaderColumn
                        dataField="reference"
                    >
                        # REFERENCIA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="payment_date"
                        dataSort
                        dataFormat={cell => cell ?
                            <RenderDate value={cell} />
                            : ""
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="name_plan"
                    >
                        Plan
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        dataField="amount"
                        dataFormat={cell => cell ?
                            <RenderDollar value={cell} />
                            : ""
                        }
                        dataSort
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="observations"
                        width='30%'
                    >
                        OBSERVACIONES
                    </TableHeaderColumn>
                </Grid>
            </CardLogo>
        )
    }
}
